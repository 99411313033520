import { Suspense } from 'react';
import { Navigate } from 'react-router';

import { LoadingSpinner } from '@/components/ui/loading-spinner';
import { profileSession } from '@/lib/profile-session';
import { useToast } from '@/hooks/use-toast';

type AllowOnboardedUserOnlyGuardProps = {
  children: React.ReactNode;
};
export const AllowOnboardedUserOnlyGuard = ({
  children,
}: AllowOnboardedUserOnlyGuardProps) => {
  const { toast } = useToast();

  if (!profileSession.isOnboarded()) {
    toast({
      title: 'Complete Onboarding',
      description: 'Finish your onboarding to proceed',
      variant: 'info',
    });
    return <Navigate to="/company-onboarding" replace />;
  }

  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center ">
          <LoadingSpinner size={50}></LoadingSpinner>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};