import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";

import FullLogo from "@/components/custom/full-logo";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp"
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { authApi } from "@/lib/auth-api";
import { authenticationSession } from "@/lib/auth-session";

const RESEND_TIMEOUT = 60;

const ActivationPage = () => {
  const navigate = useNavigate();
  const { id, code: codeParam = "" } = useParams();
  const [code, setCode] = useState(codeParam);
  const [countdown, setCountdown] = useState(RESEND_TIMEOUT);
  const [error, setError] = useState<string | null>(null);
  const isWaiting = countdown !== 0;

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleSend = () => {
    if(code.length < 4) {
      setError("Please enter a valid code.");
      return;
    }
    setError(null);
    mutateActivation({id, activationCode: code});
  };

  const handleResend = () => {
    mutateResend({id});
    setError(null);
  };

  const { mutate: mutateActivation, isPending: isPendingActivation } = useMutation({ 
    mutationFn: authApi.activate,
    onSuccess: async () => {
      const res = await authenticationSession.verifyToken();
      authenticationSession.saveResponse(res);
      navigate('/company-onboarding');
    },
    onError: () => {
      setError("Invalid code. Please try again.");
    }
  })

  const { mutate: mutateResend, isPending: isPendingResend } = useMutation({ 
    mutationFn: authApi.resend,
    onSuccess: () => {
      setCountdown(RESEND_TIMEOUT);
    }
  })

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="max-w-md w-full shadow-lg">
        <CardHeader>
          <FullLogo />
          <CardTitle className="text-2xl">Activation</CardTitle>
          <CardDescription>
            Enter the code received in your email to activate your account.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <InputOTP 
            className="text-destructive"
            maxLength={4} 
            value={code} 
            onChange={setCode} 
            onComplete={handleSend}
          >
            <InputOTPGroup className="mx-auto">
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
            </InputOTPGroup>
          </InputOTP>
          {error?.length && (
            <p className="text-destructive text-sm">{error}</p>
          )}
          <Button 
            className="mt-4" 
            disabled={isPendingActivation} 
            loading={isPendingActivation} 
            onClick={handleSend}
            >
              Activate
          </Button>
          <Button 
            variant="outline" 
            disabled={isWaiting} 
            loading={isPendingResend}
            onClick={handleResend} 
          >
            {isWaiting ? `Resend code in ${countdown}s.` : 'Resend'}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default ActivationPage;
