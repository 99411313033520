import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import FullLogo from "@/components/custom/full-logo";
import { formatUtils } from "@/lib/utils";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useToast } from "@/hooks/use-toast";
import { connectionsApi } from "@/lib/connections-api";
import { ConnectedTools } from "@/features/company-onboarding/connected-tools";
import { countConnectedTools } from "@/features/company-onboarding/utils";
import { ConnectToolFields } from "@/features/company-onboarding/connect-tool-fields";
import { profileApi, PlatformData } from "@/lib/profile-api";
import { ListResponseSchema } from "../connections";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { profileSession } from "@/lib/profile-session";

export type Tool = {
  id: string;
  name: string;
  description?: string;
  connected?: boolean;
}

const onboardingFormSchema = z.object({
  companyName: z.string().min(1, 'Company name is required').max(50),
  companyDomain: z.string().regex(formatUtils.domainRegex, 'Invalid domain address'),
  department: z.string(),
  tool: z.string(),
})

export type OnboardingFormValues = z.infer<typeof onboardingFormSchema>;

const CompanyOnboardingPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [connections, setConnections] = useState<Record<string, Tool[]>>({});
  const [showNewToolForm, setShowNewToolForm] = useState(false);

  const form = useForm<OnboardingFormValues>({
    resolver: zodResolver(onboardingFormSchema),
    defaultValues: {
      companyName: '',
      companyDomain: '',
      department: '',
      tool: '',
    }
  })

  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: ['connections'],
    queryFn: connectionsApi.fetch,
    select: data => {
      const result = ListResponseSchema.safeParse(data);
      if(!result.success) return;
      
      let allTools = Object.fromEntries(Object.entries(result.data.available).map(
        ([department, specs])=>[department, specs.map(
          tool => ({ id: tool.name, name: tool.name, description: tool.desc, connected: false })
        )]
      ))

      return {
        ...allTools,
        Accounting: [
          ...allTools.Accounting,
          ...result.data.existing.map(tool => ({
            id: tool.name, 
            name: tool.name, 
            description: "", 
            connected: true
          })),
        ]
      }
    }
  });

  useEffect(() => {
    if(!data) return;
    setConnections(data);
    setShowNewToolForm(
      countConnectedTools(data) === 0
    );
  }, [data])

  useEffect(() => {
    if(!isError) return;
    toast({
      variant: "destructive",
      title: "Error",
      description: "Failed to fetch connections",
    });
  }, [isError, toast])

  const { mutate: mutatePlatformFn, isPending: isPendingSaving } = useMutation<PlatformData,any,PlatformData>({ 
    mutationFn: profileApi.platformSet,
    onSuccess: async (data) => {
      profileSession.saveResponse(data);
      navigate('/');
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Something went wrong... ${error}`,
      });
    }
  })

  const onSubmit = (data: OnboardingFormValues) => {
    form.setError('root.serverError', {
      message: undefined,
    });

    if(countConnectedTools(connections) < 1) {
      form.setError('root.serverError', {
        message: 'At least one tool connection is required',
      });
      return;
    }

    mutatePlatformFn({
      companyName: data.companyName,
      companyDomain: data.companyDomain,
    });
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="mx-auto max-w-lg shadow-lg w-[600px]">
      <CardHeader>
        <FullLogo />
        <CardTitle className="text-xl">Company Onboarding</CardTitle>
        <CardDescription>
          Set up your company profile and connect your tools
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <div className="grid gap-4">

              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="companyName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter company name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="companyDomain"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Domain</FormLabel>
                      <FormControl>
                        <Input placeholder="example.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-between items-center">
                <CardTitle className="text-xl">Connect External Tools</CardTitle>
                <Button 
                  variant="link" 
                  type="button" 
                  className="py-1 h-fit"
                  onClick={() => setShowNewToolForm(true)} 
                  disabled={showNewToolForm}
                >
                  <Plus />
                  Add Tool
                </Button>
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center space-x-2">
                  <LoadingSpinner size={32} />
                  <span className="text-md">Loading tools...</span>
                </div>
              ) : (
                <div className="space-y-4">
                  <ConnectedTools 
                    connections={connections}
                    setConnections={setConnections}
                  />
                  {showNewToolForm && (
                    <ConnectToolFields
                      form={form}
                      connections={connections}
                      fetchConnections={refetch}
                      setShowNewToolForm={setShowNewToolForm}
                    />
                  )}
                </div>
              )}

              {form?.formState?.errors?.root?.serverError && (
                <FormMessage>
                  {form.formState.errors.root.serverError.message}
                </FormMessage>
              )}
              <Button 
                type="submit" 
                className="w-full" 
                loading={isPendingSaving} 
                disabled={isPendingSaving}
              >
                Complete onboarding
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
    </div>
  );
}

export default CompanyOnboardingPage;
