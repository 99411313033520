import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { UseFormReturn } from "react-hook-form";
import { Unplug } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Tool } from "@/app/routes/company-onboarding";
import { OnboardingFormValues } from "@/app/routes/company-onboarding";
import { OAUTH_RESULT } from "@/lib/utils";
import { useToast } from "@/hooks/use-toast";
import { connectionsApi } from "@/lib/connections-api";

type ConnectToolFieldsProps = {
  connections: Record<string, Tool[]>,
  form: UseFormReturn<OnboardingFormValues>,
  fetchConnections: () => void,
  setShowNewToolForm: React.Dispatch<React.SetStateAction<boolean>>,
}

const ConnectToolFields = ({
  connections,
  form,
  fetchConnections,
  setShowNewToolForm,
}: ConnectToolFieldsProps) => {
  const { toast } = useToast();
  useEffect(() => {
    const handleOAuthResult = (event: MessageEvent) => {
      if (event.data.type !== OAUTH_RESULT) return;

      if(event.data.isSuccess) {
        fetchConnections();
        setShowNewToolForm(false);
        form.setError('root.serverError', {
          message: undefined,
        });
        form.resetField("department");
        form.resetField("tool");
        return toast({
          variant: "success",
          title: "Authorized",
          description: "Connection has been saved",
        });
      }

      return toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create connection",
      });
    }

    window.addEventListener('message', handleOAuthResult);

    return () => {
      window.removeEventListener('message', handleOAuthResult);
    }
  }, [fetchConnections, form, setShowNewToolForm, toast])

  const handleOauth = (name: string) => {
    toast({
      variant: "info",
      title: "Authorizing...",
      description: `Starting OAuth authorization for ${name}...`,
    });
    mutateOAuthFn(name);
  }

  const { mutate: mutateOAuthFn } = useMutation({ 
    mutationFn: connectionsApi.getRedirectUrl,
    onSuccess: async (data) => {
      if (data.redirectUri) {
        window.open(
          data.redirectUri,
          "_blank",
          "width=600,height=800,top=100,left=100"
        )
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `${form.getValues().tool} OAuth is unavailable`,
      });
    }
  })

  return (
    <Card>
      <CardContent className="flex p-4 rounded space-x-4 items-center">
      <FormField
          control={form.control}
          name="department"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Department</FormLabel>
              <Select 
                onValueChange={value => {
                  field.onChange(value);
                  form.resetField("tool");
                }}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select department" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.keys(connections).map(department => (
                    <SelectItem key={department} value={department}>
                      {department}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tool"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>Tool</FormLabel>
              <Select
                onValueChange={field.onChange} 
                defaultValue={field.value} 
                value={field.value}
                disabled={!form.getValues().department}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select tool" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {connections?.[form.getValues().department]?.map(tool => !tool.connected && (
                    <SelectItem key={tool.id} value={tool.name}>
                      {tool.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <Button
          className="py-1 self-end flex-2" 
          variant="outline" 
          type="button"
          onClick={() => handleOauth(form.getValues().tool)}
          disabled={!form.getValues().tool || !form.getValues().department}
        >
          <Unplug className="text-green-600" />
          <span 
            className="text-green-600"
          >
            Connect
          </span>
        </Button>
      </CardContent>
    </Card>
  )
}

export {  ConnectToolFields }