import { authApi, UserData } from "./auth-api";

const isLoggedIn = 'isLoggedIn';
const currentUserKey = 'currentUser';

type VerifyTokenResponse = { 
  success: boolean,
  user?: UserData
}

export const authenticationSession = {
  saveResponse(response: VerifyTokenResponse) {
    localStorage.setItem(isLoggedIn, JSON.stringify(response.success));
    localStorage.setItem(currentUserKey, JSON.stringify(response.user));
  },
  getUser(): UserData | null {
    const user = localStorage.getItem(currentUserKey);
    if (user) {
      try {
        return JSON.parse(user) as UserData;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  },
  isLoggedIn(): boolean {
    return localStorage.getItem(isLoggedIn) === JSON.stringify(true);
  },
  async verifyToken(): Promise<VerifyTokenResponse> {
    try {
      const response = await authApi.check({});
      if (response.success) {
        return { success: true, user: response.result as UserData };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  clearSession() {
    localStorage.removeItem(isLoggedIn);
    localStorage.removeItem(currentUserKey);
  },
  logOut() {
    this.clearSession();

    try {
      authApi.logout({});
    } catch (error) {
      console.error("Something went wrong while logging out:", error);
    }

    window.location.href = '/sign-in';
  },
  getCurrentUser(): unknown | null {
    const user = localStorage.getItem(currentUserKey);
    if (user) {
      try {
        return JSON.parse(user);
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  },
}