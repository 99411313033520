import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AppRouter } from './app/router'
import { ThemeProvider } from "@/components/theme-provider"
import { authenticationSession } from "./lib/auth-session"
import { LoadingSpinner } from "./components/ui/loading-spinner"
import { Toaster } from "@/components/ui/toaster"
import { profileSession } from "./lib/profile-session";

const queryClient = new QueryClient();

function App() {  
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const res = await authenticationSession.verifyToken();
      authenticationSession.saveResponse(res);
      if(!res.success) {
        setIsLoggedIn(false);
        return;
      }
      const onboardingInfo = await profileSession.fetchOnboardingInfo();
      profileSession.saveResponse(onboardingInfo);
      setIsLoggedIn(res.success);
    };

    checkLoginStatus();
    return () => {
      authenticationSession.clearSession();
      profileSession.clearSession();
    }
  }, []);

  if (isLoggedIn === null) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingSpinner size={50}></LoadingSpinner>
      </div>
    );
  }

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        <Toaster />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
