import { Link } from "react-router";

type LinkOptionalProps = {
  link: string | undefined,
  children: React.ReactNode,
};

const LinkOptional = ({ link, children }: LinkOptionalProps) => {
  if(!link) {
    return <>{children}</>;
  }

  return (
    <Link to={link}>{children}</Link>
  )
}

export default LinkOptional;
