import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage, 
  FormDescription 
} from "@/components/ui/form"
import { authenticationSession } from "@/lib/auth-session"
import  { profileApi, UserUpdateData, UserUpdateDataSchema} from "@/lib/profile-api"
import { useNavigate } from "react-router"
import { useMutation } from "@tanstack/react-query"
import { useToast } from "@/hooks/use-toast"

const ProfileSettings = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const user = authenticationSession.getUser();
  const form = useForm<UserUpdateData>({
    resolver: zodResolver(UserUpdateDataSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      //email: user?.email,
    }
  })

  const { mutate, isPending } = useMutation({ 
    mutationFn: profileApi.userUpdate,
    onSuccess: data => {
      if(typeof data.result === 'boolean') return;
      authenticationSession.saveResponse({ success:true, user: data.result});
      toast({
        title: 'Profile updated',
        variant: 'success',
        description: 'Your profile has been updated',
      });
      navigate('/settings/profile');
    },
    onError: (error) => {
      form.setError('root.serverError', {
        message: error.message,
      })
    }
  })

  const onSubmit = async (values: UserUpdateData) => {
    if(!user) return;

    // check if any field has changed
    (Object.keys(values) as Array<keyof UserUpdateData>).forEach(key => {
      if (values[key].trim() !== user[key].trim()) {
        mutate({
          id: user?.id,
          data: {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
          }
        });
        return;
      }
    });
  }

  return (
    <main className="flex-1 space-y-6 max-w-2xl">
      <div>
        <h2 className="text-2xl font-semibold mb-2">
          Profile
        </h2>
        <p className="text-muted-foreground mb-4">
          This is how others will see you on the site.
        </p>
      </div>
      <Separator />
      <div className="space-y-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Fist name</FormLabel>
                  <FormControl>
                    <Input placeholder="Your first name..." {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your first name. It can be your real name or a pseudonym.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input placeholder="Your last name..." {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your last name.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="m@example.com" {...field} />
                  </FormControl>
                  <FormDescription>
                    This is your email address. You use it to log in to your account.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
            {form?.formState?.errors?.root?.serverError && (
              <FormMessage>
                {form.formState.errors.root.serverError.message}
              </FormMessage>
            )}
            <Button 
              type="submit" 
              className="w-fit"
              disabled={isPending}
              loading={isPending}
            >
              Update Profile
            </Button>
          </form>
        </Form>
      </div>
    </main>
  )
}

export default ProfileSettings;
