import { z } from "zod";
import { api } from "./api";
import { UserData } from './auth-api'
import { Response } from "@/app.interface";

export type PlatformData = {
  companyName:string,
  companyDomain:string
}

export const UserUpdateDataSchema = z.object({
  firstName: z.string().min(1, 'First name is required').max(50),
  lastName: z.string().min(1, 'Last name is required').max(50),
})
export type UserUpdateData = z.infer<typeof UserUpdateDataSchema>

export const profileApi = {
  userUpdate(data: { id: string, data: UserUpdateData }) {
    return api.patch<Response<UserData>>(
      '/user/profile',
      data
    )
  },
  platformSet(data: PlatformData) {
    return api.post<PlatformData>(
      '/user/profile/platform',
      data,
    )
  },
  platformGet(){
    return api.get<PlatformData | null>(
      '/user/profile/platform'
    )
  },
  platformUpdate(data: Partial<PlatformData>){
    return api.patch<PlatformData>(
      '/user/profile/platform',
      data
    )
  }
}