import { PersonaOptions } from "@nlux/react";
import { imagesHooks } from "@/hooks/images-hooks";

const BOT_AVATAR_URL = imagesHooks.useCopilotAvatar();
const USER_AVATAR_URL = imagesHooks.useAvatarPlaceholder();

export const persona: PersonaOptions = {
  assistant: {
      name: "OpexAI Copilot",
      avatar: BOT_AVATAR_URL,
      tagline: "Helping to make sense of your Company's data!",
  },
  user: {
      name: "Alex",
      avatar: USER_AVATAR_URL,
  }
}