import { Link, useLocation } from "react-router";

import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";

const settingsMenu = [
  {
    name: "Profile",
    path: "/settings/profile",
  },
  {
    name: "Account",
    path: "/settings/account",
  },
  {
    name: "Appearance",
    path: "/settings/appearance",
  },
  {
    name: "Notifications",
    path: "/settings/notifications",
  },
  {
    name: "Display",
    path: "/settings/display",
  },
]

type SettingsPageLayoutProps = {
  children: React.ReactNode
}

export const SettingsPageLayout = ({ 
  children
}: SettingsPageLayoutProps) => {
  const { pathname } = useLocation();
  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold mb-2">Settings</h1>
      <p className="text-muted-foreground">Manage your account settings and set e-mail preferences.</p>
      <Separator className="my-5"/>
      <div className="flex flex-col md:flex-row gap-6">
        <nav className="w-full md:w-1/4 space-y-1">
          {settingsMenu.map(({ name, path }) => (
            <Link key={path} to={path}>
              <Button
                key={name}
                variant={pathname.includes(name.toLowerCase()) ? "secondary" : "ghost"}
                className="w-full justify-start"
              >
                {name}
              </Button>
            </Link>
          ))}
        </nav>
        { children }
      </div>
    </div>
  )
}