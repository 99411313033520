import z from 'zod'
import { api } from "./api";
import { Response } from "@/app.interface";
import { formatUtils } from './utils';

export const signUpFormSchema = z.object({
  firstName: z.string().min(1, 'First name is required').max(50),
  lastName: z.string().min(1, 'Last name is required').max(50),
  email: z.string().regex(formatUtils.emailRegex, 'Invalid email address'),
  password: z.string().min(1, 'Password is required').max(50),
})

export type SignUnFormValues = z.infer<typeof signUpFormSchema>;

export type UserData = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  created: string,
  updated: string
}
type Join = UserData

type Login = Join;

type Activate = UserData;

type Resend = Join;

type Check = UserData;

type Recover = Join;

export const authApi = {
  check(request: unknown) {
    return api.get<Response<Check>>(
      '/auth/local/check',
      request,
    );
  },
  signIn(request: unknown) {
    return api.post<Login>(
      '/auth/local/login',
      request,
    );
  },
  signUp(data: SignUnFormValues) {
    return api.put<Response<Join>>(
      '/auth/local/join',
      data,
    );
  },
  activate(request: unknown) {
    return api.post<Response<Activate>>(
      '/auth/local/activate',
      request,
    );
  },
  recover(request: unknown) {
    return api.post<Response<Recover>>(
      '/auth/local/recover',
      request,
    );
  },
  reset(request: unknown) {
    return api.post<Response<Recover>>(
      '/auth/local/reset',
      request,
    );
  },
  resend(request: unknown) {
    return api.post<Response<Resend>>(
      '/auth/local/resend',
      request,
    );
  },
  logout(request: unknown) {
    return api.get<Response<any>>(
      '/auth/local/logout',
      request,
    );
  },
}