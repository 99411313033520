import { useState } from "react";
import { Link, useNavigate } from "react-router";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { HttpStatusCode } from "axios";
import { useMutation } from '@tanstack/react-query'

import { Button } from "@/components/ui/button";
import { 
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { 
  Form, 
  FormControl, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from "@/components/ui/form";
import FullLogo from "@/components/custom/full-logo";
import { formatUtils } from "@/lib/utils";
import { authApi } from "@/lib/auth-api";
import { api } from "@/lib/api";
import { authenticationSession } from "@/lib/auth-session";
import { useToast } from "@/hooks/use-toast";
import { profileSession } from "@/lib/profile-session";


const signInFormSchema = z.object({
  email: z.string().regex(formatUtils.emailRegex, 'Invalid email address'),
  password: z.string().min(1, 'Password is required').max(50),
})

type SignInFormValues = z.infer<typeof signInFormSchema>;

const SignInPage = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isPending, setIsPending] = useState(false);
  const form = useForm<SignInFormValues>({
    resolver: zodResolver(signInFormSchema),
    defaultValues: {
      email: '',
      password: '',
    }
  })

  const { mutate } = useMutation({ 
    mutationFn: authApi.signIn,
    onSuccess: async () => {
      mutateVerifyToken();
    },
    onError: (error) => {
      setIsPending(false);
      if (api.isError(error)) {
        switch (error.response?.status) {
          case HttpStatusCode.Unauthorized:
          case HttpStatusCode.BadRequest: {
            form.setError('root.serverError', {
              message: 'Invalid email or password',
            });
            break;
          }
          default: {
            form.setError('root.serverError', {
              message: 'Something went wrong, please try again later',
            });
            break;
          }
        }
        return;
      }
    }
  })

  const { mutate: mutateVerifyToken } = useMutation({ 
    mutationFn: authenticationSession.verifyToken,
    onSuccess: async (data) => {
      authenticationSession.saveResponse(data);
      const onboardingInfo = await profileSession.fetchOnboardingInfo();
      profileSession.saveResponse(onboardingInfo);
      setIsPending(false);
      navigate('/');
    },
    onError: () => {
      setIsPending(false);
      toast({
        variant: 'destructive',
        title: 'Something went wrong',
        description: 'Please try again later',
      })
    }
  })

  const onSubmit = (values: SignInFormValues) => {
    form.setError('root.serverError', {
      message: undefined,
    });
    setIsPending(true);
    mutate(values);
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="max-w-md w-full shadow-lg">
        <CardHeader>
          <FullLogo />
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className="grid gap-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input placeholder="mail@example.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center">
                        <FormLabel>Password</FormLabel>
                        <Link to="/auth/recovery" className="ml-auto inline-block text-sm underline">
                          Forgot your password?
                        </Link>
                      </div>
                      <FormControl>
                        <Input placeholder="*******" type="password" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {form?.formState?.errors?.root?.serverError && (
                  <FormMessage>
                    {form.formState.errors.root.serverError.message}
                  </FormMessage>
                )}
                <Button type="submit" className="w-full" loading={isPending} disabled={isPending}>
                  Login
                </Button>
              </div>
            </form>
          </Form>
          <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{" "}
            <Link to="/sign-up" className="underline">
              Sign up
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default SignInPage;
