import { Link, useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import FullLogo from "@/components/custom/full-logo";
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { authApi } from "@/lib/auth-api";
import { formatUtils } from "@/lib/utils";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";

const recoveryFormSchema = z.object({
  email: z.string().regex(formatUtils.emailRegex, 'Invalid email address'),
})

type RecoveryFormValues = z.infer<typeof recoveryFormSchema>;

const RecoveryPage = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const form = useForm<RecoveryFormValues>({
    resolver: zodResolver(recoveryFormSchema),
    defaultValues: {
      email: '',
    }
  })

  const { mutate, isPending } = useMutation({
    mutationFn: authApi.recover,
    onSuccess: (data) => {
      toast({
        variant: "success",
        title: "Success",
        description: "Check your email for a recovery code.",
      });
      if(typeof data.result !== "boolean") {
        navigate(`/auth/reset/${data.result.id}`);
      }
    },
    onError: () => {
      form.setError('root.serverError', {
        message: "Something went wrong. Please try again later...",
      });
    }
  })

  const onSubmit = (values: RecoveryFormValues) => {
    console.log(values);
    form.setError('root.serverError', {
      message: undefined,
    });
    mutate({ email: values.email });
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <Card className="max-w-md w-full shadow-lg">
        <CardHeader>
          <FullLogo />
          <CardTitle className="text-2xl">Recovery</CardTitle>
          <CardDescription>
            Enter your email to reset your password.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="mail@example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form?.formState?.errors?.root?.serverError && (
                <FormMessage>
                  {form.formState.errors.root.serverError.message}
                </FormMessage>
              )}
              <Button
                type="submit"
                className="mt-4 w-full" 
                disabled={isPending} 
                loading={isPending} 
              >
                Recover
              </Button>
              <div className="mt-4 text-center text-sm">
                Go back to{" "}
                <Link to="/sign-in" className="underline">
                   Sign In
                </Link>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
}

export default RecoveryPage;
