import { Suspense } from 'react'
import { Navigate } from 'react-router'

import { LoadingSpinner } from '@/components/ui/loading-spinner'
import { authenticationSession } from '@/lib/auth-session'

type AllowOnlyLoggedInUserOnlyGuardProps = {
  children: React.ReactNode;
};
export const AllowOnlyLoggedInUserOnlyGuard = ({
  children,
}: AllowOnlyLoggedInUserOnlyGuardProps) => {

  if (!authenticationSession.isLoggedIn()) {
    return <Navigate to="/sign-in" replace />;
  }

  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center ">
          <LoadingSpinner size={50}></LoadingSpinner>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};