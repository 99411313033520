import { useEffect, useState } from "react"
import { z } from "zod"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../lib/api"
import { 
  Table,
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { ConnectionDropdownMenu } from "@/features/connections/connection-dropdown-menu"
import { connectionsApi } from "@/lib/connections-api"
import { useToast } from "@/hooks/use-toast"
import { NewCoonectionDialog } from "@/features/connections/new-connection-dialog"

// Enums
enum ConnectionInterval {// it will be triggered by scheduler, to be implemented on backend
  NONE = 0,
  HOURLY = 1,
  DAILY = 2,
  WEEKLY = 3
}

export enum DispayConnectionInterval {
  MANUAL = ConnectionInterval.NONE,
  HOURLY = ConnectionInterval.HOURLY,
  DAILY = ConnectionInterval.DAILY
}

export enum StrategyView {
  OAUTH = 'oauth',
  API_TOKEN = 'api_token',//this is not oauth, just "copy an access token" sort of authorisation
  IN_DEV = ''
}

// Zod Schemas
const ConnectionStatusEnum = z.enum(['active', 'wanting']) //wanting to indicate some troubles
const ConnectionIntervalSchema = z.nativeEnum(DispayConnectionInterval)
const StrategyViewSchema = z.nativeEnum(StrategyView)

export const ConnectionViewSchema = z.object({ // for existing (connected)
  name: z.string(),
  domains: z.array(z.string()),
  status: ConnectionStatusEnum,
  interval: ConnectionIntervalSchema,
  lastUpdate: z.string().nullable(),
  lastWarnings: z.array(z.string())
})

const ConnectionSpecViewSchema = z.object({ // for not existing
  name: z.string(),
  desc: z.string(),
  department: z.string(),
  domains: z.array(z.string()),
  strategy: StrategyViewSchema
})

export const ListResponseSchema = z.object({
  existing: z.array(ConnectionViewSchema),
  available: z.record(z.string(), z.array(ConnectionSpecViewSchema))
})

export const AuthorizeResponseSchema = z.object({
  redirectUri: z.string().url()
})

// Types
// type ConnectionStatus = z.infer<typeof ConnectionStatusEnum>
export type ConnectionView = z.infer<typeof ConnectionViewSchema>
export type ConnectionSpecView = z.infer<typeof ConnectionSpecViewSchema>
export type AuthorizeResponse = z.infer<typeof AuthorizeResponseSchema>

type ApiTokenRequest = {
  token: string
}

export type AvailabeConnections = Record<string, ConnectionSpecView[]>;

export type IntervalRequest = {
  interval: DispayConnectionInterval
}

const ConnectionsPage = () => {
  const { toast } = useToast();
  const [activeConnections, setActiveConnections] = useState<ConnectionView[]>([]);
  const [availableConnections, setAvailableConnections] = useState<AvailabeConnections>({});
  const [isLoading, setIsLoading] = useState(true);

  const { data: connections, refetch: fetchConnections, isError } = useQuery({
    queryKey: ['connections'],
    queryFn: connectionsApi.fetch,
    select: data => {
      const result = ListResponseSchema.safeParse(data);
      if(!result.success) {
        throw new Error('Error parsing connections');
      } else {
        return result;
      }
    }
  });

  useEffect(() => {
    if(!connections) return;
    setIsLoading(false);
    setActiveConnections(connections.data.existing);
    const notEmptyDepartments = Object.fromEntries(
      Object.entries(connections.data.available).filter(([_, connections]) => connections.length > 0)
    )
    setAvailableConnections(notEmptyDepartments);
  }, [connections])

  useEffect(() => {
    if(!isError) return
    setIsLoading(false);
    toast({
      variant: 'destructive',
      title: 'Error',
      description: 'Failed to fetch connections',
    })
  }, [isError, toast])
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleApiToken = async (name: string, token: string) => {
    try {
      const requestData: ApiTokenRequest = { token }
      await api.post(`/connections/token/${name}`, requestData) // it's simply saved on backend
      fetchConnections()
    } catch (error) {
      console.error('API token connection error:', error)
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to add API token connection',
      })
    }
  }
  
  if (isLoading) {
    return (
      <div className="p-4 space-y-4">
        {(new Array(5)).map(i => (
          <div key={i} className="h-12 bg-gray-200 animate-pulse rounded-lg"/>
        ))}
      </div>
    )
  }

  return (
    <div className="mx-auto p-6 space-y-5">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Connections</h1>
          <p className="text-muted-foreground">Manage your connections to external systems</p>
        </div>
        <NewCoonectionDialog 
          availableTools={availableConnections}
          fetchConnections={fetchConnections}
        />
      </div>
      <Table>
        <TableCaption>
          {activeConnections.length > 0 ? "A list of your connections" : "No active connections"}
        </TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead >Service</TableHead>
            <TableHead className="w-1/2">Description</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {activeConnections.map(connection => (
            <TableRow key={connection.name}>
              <TableCell className="font-medium">{connection.name}</TableCell>
              <TableCell>{`Default description for ${connection.name}`}</TableCell>
              <TableCell><Badge variant="success">Connected</Badge></TableCell>
              <TableCell className="flex justify-end">
                <ConnectionDropdownMenu
                  connection={connection} 
                  setConnections={setActiveConnections} 
                  fetchConnections={fetchConnections}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ConnectionsPage
