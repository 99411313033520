import { PlatformData, profileApi } from "./profile-api";

const onboardingInfoKey = 'onboardingInfo';

export const profileSession = {
  saveResponse(onboardingInfo: PlatformData | null) {
    if(onboardingInfo === null) return;
    localStorage.setItem(onboardingInfoKey, JSON.stringify(onboardingInfo));
  },
  isOnboarded(): boolean {
    return this.getOnboardingInfo() !== null;
  },
  getOnboardingInfo(): PlatformData | null {
    const info = localStorage.getItem(onboardingInfoKey);
    if (!info) return null;
    try {
      return JSON.parse(info) as PlatformData;
    } catch (e) {
      return null;
    }    
  },
  async fetchOnboardingInfo(): Promise<PlatformData | null> {
    try {
      return profileApi.platformGet();
    } catch (error) {
      return null;
    }
  },
  clearSession() {
    localStorage.removeItem(onboardingInfoKey);
  },
}