import { useState } from "react";
import "@nlux/themes/nova.css";
import './theme-variables.css';

import { Popover, PopoverAnchor, PopoverContent } from "@/components/ui/popover";
import { AiChat, ConversationStarter, useAsBatchAdapter } from "@nlux/react";
import { batchCopilotAdapter } from "@/features/copilot/openai-adapter";
import { useTheme } from "@/components/theme-provider";
import { persona } from "./persona";
import { 
  MessageSquare,
  X,
  EllipsisVertical,
  ChartNoAxesColumnIncreasing,
  Telescope,
  Target
} from "lucide-react";
import { Button } from "@/components/ui/button";

const CopilotChat = () => {
  const { theme } = useTheme();
  const adapter = useAsBatchAdapter(batchCopilotAdapter)
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

  const conversationStarters: ConversationStarter[] = [
    { prompt: 'Analyse trends in the data', icon: <ChartNoAxesColumnIncreasing /> },
    { prompt: 'What optimization insights do you recommend?', icon: <Telescope /> },
    { prompt: 'How can I enhance data accuracy and consistency?', icon: <Target /> },
  ]

  const chatComponent = (
    <AiChat
      adapter={adapter}
      conversationOptions={{ layout: 'bubbles', conversationStarters }}
      displayOptions={{ 
        colorScheme: theme === "system" ? "auto" : theme,
        width: "100%",
        height: "calc(100% - 40px)"
      }}
      composerOptions={{ placeholder: "Type your message...",
        autoFocus: true,
        submitShortcut: 'Enter',
        hideStopButton: false,
      }}
      personaOptions={persona}
    />
  )

  return (
    <div className="fixed bottom-3 right-6">
      <Popover
        open={isChatOpen}
        onOpenChange={() => !isChatOpen && setIsChatOpen(false)}
      >
        <PopoverAnchor>
          {!isChatOpen && (
            <Button
              size="icon"
              className="w-14 h-14 rounded-full z-40 fixed right-4 bottom-4"
              onClick={() => setIsChatOpen(!isChatOpen)}
            >
              <MessageSquare className="h-4 w-4" />
            </Button>
          )}
        </PopoverAnchor>
        <PopoverContent
          align="end"
          side="bottom"
          className="w-[33vw] h-[81vh] min-w-[300px] sm:min-w-[500px] p-0"
        >
          {isChatOpen && (
            <div className="h-full relative bg-white dark:bg-[#151515] rounded-md">
              <div className="w-full flex justify-end">
                <Button variant="ghost" className="rounded-none">
                  <EllipsisVertical className="w-4 h-4" />
                </Button>
                <Button 
                  variant="ghost" 
                  className="rounded-none rounded-tr-sm" 
                  onClick={() => setIsChatOpen(false)}
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
              {chatComponent}
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}

export { CopilotChat }