import { 
  ArrowUp,
  EllipsisVertical, 
  Unplug 
} from "lucide-react"
import { RefetchOptions, useMutation, UseQueryResult } from "@tanstack/react-query"

import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu"
import {
  ConnectionView, 
  ConnectionViewSchema
} from "@/app/routes/connections"
import { SelectIntervalDialog } from "./select-interval-dialog"
import { useToast } from "@/hooks/use-toast"
import { connectionsApi } from "@/lib/connections-api"

type ConnectionDropdownMenuProps = {
  connection: ConnectionView,
  fetchConnections: (options?: RefetchOptions) => Promise<UseQueryResult>,
  setConnections: React.Dispatch<React.SetStateAction<ConnectionView[]>>,
}

const ConnectionDropdownMenu = ({
  connection,
  setConnections,
  fetchConnections,
}: ConnectionDropdownMenuProps) => {
  const { toast } = useToast();
  
  // const [triggeringConnection, setTriggeringConnection] = useState<string>();

  const { mutate: mutateDeleteFn } = useMutation({ 
    mutationFn: connectionsApi.delete,
    onSuccess: async (_data, name) => {
      toast({
        variant: "success",
        title: "Deleted",
        description: `${name} connection has been deleted`,
      });
      fetchConnections();
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed delete connecton: ${error.message}`,
      });
    }
  })

  const handleTrigger = async (name: string) => {
    // setTriggeringConnection(name)
    try {
      //it's waiting till data is fetched on backend, do we have sockets in project, maybe they'll be better than logpolling?
      const data = await connectionsApi.trigger(name);
      const result = ConnectionViewSchema.safeParse(data) // updated (with warnings, last update etc) connectin info is ultimately returned
      if (result.success) {
        // setExisting(existing.map(connection =>
        //   connection.name === name ? result.data : connection
        // ))
      } else {
        console.error('Invalid trigger response:', result.error);
        toast({
          variant: "destructive",
          title: "Error",
          description: 'Invalid trigger response format',
        });
      }
    } catch (error) {
      console.error('Trigger connection error:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: 'Invalid trigger response format',
      });
    } finally {
      // setTriggeringConnection(undefined)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <EllipsisVertical size={18} className="cursor-pointer hover:text-gray-500" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem 
          className="cursor-pointer"
          onSelect={() => mutateDeleteFn(connection.name)}
        >
          <Unplug className="mr-2 h-4 w-4 text-destructive" />
          <span className="text-destructive">
            Disconnect
          </span>
        </DropdownMenuItem>

        <DropdownMenuItem className="cursor-pointer" onSelect={() => handleTrigger(connection.name)}>
          <ArrowUp className="mr-2 h-4 w-4" />
          <span>Sync Now</span>
        </DropdownMenuItem>

        <SelectIntervalDialog connection={connection} updateConnections={setConnections} />
                 
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { ConnectionDropdownMenu }
