import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RefetchOptions, useMutation, UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { 
  AuthorizeResponseSchema, 
  AvailabeConnections, 
  ConnectionSpecView, 
  StrategyView 
} from "@/app/routes/connections";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { connectionsApi } from "@/lib/connections-api";
import { OAUTH_RESULT } from "@/lib/utils";
import { Separator } from "@/components/ui/separator";

const newConnectionFormSchema = z.object({
  department: z.string().min(1, {
    message: "Department is required",
  }),
  tool: z.string().min(1, {
    message: "Tool is required",
  }),
})

export type NewConnectionFormValues = z.infer<typeof newConnectionFormSchema>;


type NewCoonectionDialogProps = {
  availableTools: AvailabeConnections,
  fetchConnections: (options?: RefetchOptions) => Promise<UseQueryResult>,
}

const NewCoonectionDialog = ({ availableTools, fetchConnections }: NewCoonectionDialogProps) => {
  const { toast } = useToast();
  const pendingConnection = useRef<ConnectionSpecView | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<NewConnectionFormValues>({
    resolver: zodResolver(newConnectionFormSchema),
    defaultValues: {
      department: '',
      tool: '',
    }
  })

  const department = form.watch("department");
  const tool = form.watch("tool");

  useEffect(() => {
    const handleOAuthResult = (event: MessageEvent) => {
      if (event.data.type !== OAUTH_RESULT) return;
      
      if(event.data.isSuccess) {
        toast({
          variant: "success",
          title: "Authorized",
          description: `${pendingConnection.current?.name} connection has been authorized`,
        });
        
        fetchConnections();
        setIsOpen(false);
        return
      }

      return toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to authorize ${tool}`,
      });
    }

    window.addEventListener('message', handleOAuthResult);

    return () => {
      window.removeEventListener('message', handleOAuthResult);
    }
  }, [fetchConnections, tool, toast])

  const handleOauth = (name: string) => {
    const connection = availableTools[department].find((tool) => tool.name === name);
    if(!connection) return;
    // check if connection is in development
    if(connection.strategy === StrategyView.IN_DEV) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `${name} OAuth is unavailable`,
      });
      return;
    };
    
    toast({
      variant: "info",
      title: "Authorizing...",
      description: `Starting OAuth authorization for ${name}...`,
    });
    pendingConnection.current = connection;
    mutateOAuthFn(name);
  }
  const { mutate: mutateOAuthFn } = useMutation({ 
    mutationFn: connectionsApi.getRedirectUrl,
    onSuccess: async (data) => {
      const result = AuthorizeResponseSchema.safeParse(data);
      if (result.success) {
        window.open(
          result.data.redirectUri,
          "_blank",
          "width=600,height=800,top=100,left=100"
        )
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to start OAuth authorization: ${error.message}`,
      });
    }
  })

  const onSubmit = (values: NewConnectionFormValues) => {
    form.setError('root.serverError', {
      message: undefined,
    });
    handleOauth(values.tool);
  }

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if(open) {
      form.reset();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger>
        <Button>New connection</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Connection</DialogTitle>
          <DialogDescription>
            Connect your tools securely to enable seamless integrations
          </DialogDescription>
          <div>
            <Separator className="my-2" />
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="department"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Department</FormLabel>
                    <Select 
                      onValueChange={value => {
                        field.onChange(value);
                        form.resetField("tool");
                      }}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select department" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.keys(availableTools).map(department => (
                          <SelectItem key={department} value={department}>
                            {department}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="tool"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Tool</FormLabel>
                    <Select 
                      disabled={!department}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select tool" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                      {availableTools[department]?.map(tool => (
                        <SelectItem key={tool.name} value={tool.name}>
                          {tool.name}
                        </SelectItem>
                      ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button 
                  className="align-self-end" 
                  disabled={!department || !tool} 
                  type="submit"
                >
                  Connect {tool}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export { NewCoonectionDialog }
