import { Link, useLocation } from "react-router";

import { cn } from "@/lib/utils"
import { logoHooks } from "@/hooks/logos-hooks";
import MobileNav from "./mobile-nav";
import NavbarDropdown from "./navbar-dropdown";

export const navLinks = [
  { to: '/', label: 'Dashboard' },
  { to: '/connections', label: 'Connections' },
  { to: '/automations', label: 'Automations' },
]

const DesktopNav = () => {
  const { pathname } = useLocation();
  const logoIconUrl = logoHooks.useLogoIcon();
  
  return (
    <nav className="mx-auto w-full hidden md:flex justify-between items-center max-w-screen-xl 2xl:max-w-[1700px] h-16 py-1 pr-4">
      <div className="flex space-x-8 items-center">
        <Link to="/" className="flex items-center space-x-2">
          <div className="flex justify-center items-center h-16 w-16 bg-primary">
            <img src={logoIconUrl} alt="OperateAI" className="h-11 w-11" /> 
          </div>
          <span className="hidden font-bold lg:inline-block">
            OperateAI
          </span>
        </Link>
        {navLinks.map(link => (
          <Link
            key={link.label}
            to={link.to}
            className={cn(
              "transition-colors hover:text-foreground/80",
              pathname === link.to ? "text-foreground" : "text-foreground/60"
            )}
          >
            {link.label}
          </Link>
        ))}
      </div>
      <NavbarDropdown isMobile={false} />
    </nav>
  )
}

const Navbar = () => {
  return (
    <>
      <DesktopNav />
      <MobileNav />
    </>
  )
}

export default Navbar;
