import './index.css'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import App from './app'

// Routing based on
// https://github.com/remix-run/react-router/blob/main/docs/getting-started/installation.md#basic-installation

// Redux toolkit persist based on
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist

const container = document.getElementById('root')!
const root = ReactDOM.createRoot(container)

root.render(
  <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
